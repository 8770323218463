export default {
    defaultsoutes: [
        {
            id: 1,
            name: 'Partners',
            title: 'Партнеры',
            hash: ''
        }
    ]
}


