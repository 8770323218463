<template>
    <div>
        <vue-title title="Партнеры | KEL.KZ"></vue-title>
        <Header :routes="headerRoutes.defaultsoutes"/>
        <div class="container py-4">
            <div class="text-center mb-3">
                <h1 class="mb-4 page-title">Наши партнеры</h1>
            </div>
            <div class="row">
                <div class="col-12 col-md-6 col-lg-4 text-center d-flex align-items-center justify-content-center mb-5 mb-md-5">
                    <img src="@/assets/images/partners/1.png" class="rounded w-75">
                </div>
                <div class="col-12 col-md-6 col-lg-4 text-center d-flex align-items-center justify-content-center mb-5 mb-md-5">
                    <img src="@/assets/images/partners/2.png" class="rounded w-75">
                </div>
                <div class="col-12 col-md-6 col-lg-4 text-center d-flex align-items-center justify-content-center mb-5 mb-md-5">
                    <img src="@/assets/images/partners/3.png" class="rounded w-40">
                </div>
                <div class="col-12 col-md-6 col-lg-4 text-center d-flex align-items-center justify-content-center mb-5 mb-md-5">
                    <img src="@/assets/images/partners/4.png" class="rounded w-75">
                </div>
                <div class="col-12 col-md-6 col-lg-4 text-center d-flex align-items-center justify-content-center mb-5 mb-md-5">
                    <i class="far fa-handshake text-primary" style="font-size: 120px"></i>
                </div>
                <div class="col-12 col-md-6 col-lg-4 text-center d-flex align-items-center justify-content-center mb-5 mb-md-5">
                    <img src="@/assets/images/partners/5.png" class="rounded w-45">
                </div>
                <div class="col-12 col-md-6 col-lg-4 text-center d-flex align-items-center justify-content-center mb-5 mb-md-5">
                    <img src="@/assets/images/partners/6.png" class="rounded w-50">
                </div>
                <div class="col-12 col-md-6 col-lg-4 text-center d-flex align-items-center justify-content-center mb-5 mb-md-5">
                    <img src="@/assets/images/partners/7.png" class="rounded w-50">
                </div>
                <div class="col-12 col-md-6 col-lg-4 text-center d-flex align-items-center justify-content-center mb-5 mb-md-5">
                    <img src="@/assets/images/partners/8.png" class="rounded w-50">
                </div>
            </div>
        </div>
        <Footer/>
    </div>
</template>

<script>
    import {Header, Footer} from '../../shared/components';
    import headerRoutes from './header-routes';

    export default {
        name: "Partners",
        components: {
            Header,
            Footer
        },
        data() {
            return {
                headerRoutes
            }
        }
    }
</script>

<style scoped>
    .w-40 {
        width: 40% !important;
    }
    .w-45 {
        width: 45% !important;
    }
</style>
